import __Vue from '@fwk-node-modules/vue';
import { createStore, types } from '@fwk-client/modules/shop/stores';
import { getters as cartGetters } from '@fwk-client/modules/shop/stores/cart';
import { types as shopTypes, getters as shopGetters } from '@fwk-client/modules/shop/stores/shop';
import { getters as orderGetters } from '@fwk-client/modules/shop/stores/order';
import { Cart } from '@fwk-client/modules/shop/helpers/cart';
import { Shop } from '@fwk-client/modules/shop/helpers/shop';
import { Contact } from '@fwk-client/modules/shop/helpers/contact';
import { Payment } from '@fwk-client/modules/shop/helpers/payment';
import { Order } from '@fwk-client/modules/shop/helpers/order';
import { Product } from '@fwk-client/modules/shop/helpers/product';
import { Price } from '@fwk-client/modules/shop/vue';
import { Delivery } from './helpers/delivery';
import { Store } from 'vuex';
import { CurrencyCodes } from '@igotweb-node-api/api/modules/payment/src/models/bean/Currency';
import { Activity } from './helpers/activity';

let Vue:typeof __Vue // bind on install
let installed:boolean = false;

function init(this: __Vue) {

  const options = this.$options

  // We inject the $shop features
  if (options.parent && options.parent.$shop) {
    this.$shop = options.parent.$shop;
  }
  else if(!this.$shop) {
    if(process.env.CONSOLE == "LOG") {
      console.log("SHOP PLUGIN - WE REGISTER $SHOP FEATURES TO APP INSTANCE");
    }
    // We create the $shop instance for the parent component
    this.$shop = {
      cart: new Cart(this),
      shop: new Shop(this),
      contact: new Contact(this),
      payment: new Payment(this),
      order: new Order(this),
      product: new Product(this),
      activity: new Activity(this),
      delivery: new Delivery(this)
    }
  }
}

/**
 * registerStore
 * This method has to be called to register the CMS PLUGIN store
 * @param store 
 */
 function registerStore(store:Store<any>) {
  if(process.env.CONSOLE == "LOG") {
    console.log("SHOP PLUGIN - WE REGISTER THE SHOP STORE");
  }
  
  // We create the shop store
  var shopStore = createStore();
  // We register the shop module in the store
  store.registerModule("shop", shopStore, { preserveState: store.state.shop !== undefined });
}

/**
 * populateStore
 * This method has to be called to populate store based on server state
 * @param store 
 */
 function populateStore(store:Store<any>) {
  // We check if we have shop provided by server to populate the store
  if(store.state.server && store.state.server.shop) {
    if(process.env.CONSOLE == "LOG") {
      console.log("SHOP PLUGIN - WE COPY THE SHOP FROM SERVER");
    } 
    store.commit('shop/shop/'+shopTypes.mutations.SET_SHOP, store.state.server.shop)
  }
}

/**
 * install
 * This method install the plugin.
 * The code is based on vue-router (https://github.com/vuejs/vue-router/blob/dev/src/install.js)
 * @param Vue 
 * @param options 
 */
function install(_Vue: typeof __Vue): void {

  if (Vue && _Vue === Vue || installed) {
    if(process.env.CONSOLE == "LOG") {
      console.log('SHOP PLUGIN - ALREADY INSTALLED - Vue.use(ShopPlugin) should be called only once.')
    }
    return
  }
  
  Vue = _Vue
  installed = true;
  
  if(process.env.CONSOLE == "LOG") {
    console.log("SHOP PLUGIN - WE INSTALL THE PLUGIN");
  }

  Vue.$shop = {
    router : {
      beforeEach : Shop.routerBeforeEach
    }
  }

  Vue.mixin({ 
    beforeCreate: init,

    methods : {
      formatPriceAmount(price?:Price) {
        if(!price) { return "-"; }
        if(!price.currencyCode) { price.currencyCode = CurrencyCodes.EUR; }
        var languageCode = this.$store.state.languages.currentLanguageCode;
        return new Intl.NumberFormat(languageCode, { style: 'currency', currency: price.currencyCode }).format(price.amount);
      },
      formatPriceNonDiscountedAmount(price?:Price) {
        if(!price) { return "-"; }
        if(!price.currencyCode) { price.currencyCode = CurrencyCodes.EUR; }
        var languageCode = this.$store.state.languages.currentLanguageCode;
        return new Intl.NumberFormat(languageCode, { style: 'currency', currency: price.currencyCode }).format(price.nonDiscountedAmount);
      }
    },

    // We add some computed properties
    computed : {
      $shop_isTopCartDisplayed : {
        get: function (this:__Vue) {
          return this.$store.getters['shop/' + types.getters.IS_TOP_CART_DISPLAYED];
        },
        set: function (this:__Vue, isTopCardDisplayed:boolean) {
          this.$store.commit('shop/' + types.mutations.SET_TOP_CART_DISPLAYED, isTopCardDisplayed);
        }
      },
      $shop_isCartLoading : {
        get: function (this:__Vue) {
          return this.$store.getters['shop/' + types.getters.IS_CART_LOADING];
        }
      },
      $shop_isCartProductsLoading : {
        get: function (this:__Vue) {
          return this.$store.getters['shop/' + types.getters.IS_CART_PRODUCTS_LOADING];
        }
      },
      $shop_isCartContactLoading : {
        get: function (this:__Vue) {
          return this.$store.getters['shop/' + types.getters.IS_CART_CONTACT_LOADING];
        }
      },
      $shop_productsToDisplay : {
        get: function (this:__Vue) {
          var selectedCategory = this.$store.getters['shop/'+types.getters.GET_SELECTED_CATEGORY];
          return this.$shop.product.getProductsFromCategoryID(selectedCategory);
        }
      },
      $shop_isCategorySelected : {
        get: function (this:__Vue) {
          return (category:any) => {
            return this.$store.getters['shop/'+types.getters.IS_CATEGORY_SELECTED](category);
          }
        }
      },
      $shop_activitiesToDisplay : {
        get: function (this:__Vue) {
          var selectedCategory = this.$store.getters['shop/'+types.getters.GET_SELECTED_ACTIVITY_CATEGORY];
          return this.$shop.activity.getActivitiesFromCategoryID(selectedCategory);
        }
      },
      $shop_contact : {
        get: function (this:__Vue) {
          return this.$store.getters['shop/'+types.getters.GET_CONTACT];
        }
      },
      ...cartGetters,
      ...shopGetters,
      ...orderGetters
    }
  });

}

export default {
  install,
  registerStore,
  populateStore
}