import { languagesGettersPath, languagesTypes } from '@fwk-client/store/types';
import Vue from '@fwk-node-modules/vue';
import VueRouter , { Route } from '@fwk-node-modules/vue-router';
import { gettersPath as agencyGettersPath, types as agencyTypes } from '../stores/agency';
import { Agency } from './agency';
import { Apartment } from './apartment';
import { Cart } from './cart';
import { types as cartTypes} from '@fwk-client/modules/hospitality/stores/cart';


export enum HospitalityData {
    CART = 'CART'
}

export class Hospitality {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * routerBeforeEach
     * This helpers needs to be added in router instance as before each.
     * It checks that the hospitality requested exists.
     * @param to - The destination route
     * @param from - The origin route
     * @param next - the callback
     */
    static routerBeforeEach(this:VueRouter, to:Route, from:Route, next:any) {
        var languageCode = this.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];

        // We check if we are currently redirecting to 404 page
        var redirectPath = '';
        if(languageCode) { redirectPath += '/'+languageCode; }
        redirectPath += '/404';
        var message = "HOSPITALITY PLUGIN - HOSPITALITY - ROUTER BEFORE EACH (To: " + to.fullPath + ") - 404 REDIRECTION ("+redirectPath+")";

        if(to.fullPath == redirectPath) {
            return next();
        }
            
        // We load the data if needed
        var promises:Promise<void>[] = [];

            
        // We get the components from page content or route meta
        var hospitality = this.app.$cms.utils.getRouteMetaData(to, "hospitality");
        if(hospitality && hospitality.agencies) {
            for(var agencyCode of hospitality.agencies) {
                // We check if the agency is already available
                var existingAgency = this.app.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]](agencyCode)
                // We retrieve the agency information
                if(!existingAgency) {
                    var agencyHelper = new Agency(this.app);
                    promises.push(agencyHelper.getAgency(agencyCode));
                }
            }
        }
        if(hospitality && hospitality.apartments) {
            var agencyHelper = new Agency(this.app);
            for(var agencyCode of hospitality.apartments) {
                promises.push(agencyHelper.updateApartments(agencyCode));
            }
        }
        if(hospitality && hospitality.apartmentCodeParam) {
            // We check if we want to retrieve the content of a post
            console.log("hospitality.apartmentCodeParam: "+hospitality.apartmentCodeParam)
            console.log(to.params);
            var apartmentCode = to.params[hospitality.apartmentCodeParam];
            if(apartmentCode) {
                var apartmentHelper = new Apartment(this.app);
                promises.push(apartmentHelper.getApartment(apartmentCode));
            }
        }

        // We check if we have the order available while trying to reach confirmation page
        if(hospitality && hospitality.isConfirmation) {
            var booking = this.app.$hospitality_booking;
            if(!booking) {
                if(process.env.CONSOLE == "LOG") { 
                    console.log("HOSPITALITY PLUGIN - HOSPITALITY - ROUTER BEFORE EACH - IS CONFIRMATION - THERE IS NO BOOKING IN THE STORE");
                }
                return next(redirectPath);
            }
        }
        
        if(hospitality && hospitality.data) {
            for(var data of hospitality.data) {
                var cartRetrieved = this.app.$store.getters['hospitality/cart/' + cartTypes.getters.GET_CART_RETRIEVED];
                if(data == HospitalityData.CART && !cartRetrieved) {
                    var cartHelper = new Cart(this.app);
                    promises.push(cartHelper.refreshCart());
                }
            }
        }
            
        return Promise.all(promises).then(() => {
            next();
        })
        .catch((error) => {
            if(process.env.CONSOLE == "LOG") { 
                console.log(message); 
                console.log(error);
            }
            return next(redirectPath);
        })
    }

}
