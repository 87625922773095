
import Vue from '@fwk-node-modules/vue';
import VueRouter , { Route } from '@fwk-node-modules/vue-router';
import * as api from '@fwk-client/utils/api';
import { updateStoreFromBackend as updateShopStoreFromBackend} from '@fwk-client/modules/shop/stores';
import { updateStoreFromBackend as updateVigneronOnlineStoreFromBackend } from '../store/vigneron-online';
import { languagesGettersPath, languagesTypes, routerTypes } from '@fwk-client/store/types';
import { types as vigneronOnlineTypes } from '../store/vigneron-online';

export enum ShopData {
    SHOPS = 'SHOPS'
}

const NO_SHOP_ERROR = "NO_SHOP_ERROR";

/**
 * routerBeforeEach
 * This helpers needs to be added in router instance as before each.
 * It checks that the shop requested exists.
 * @param to - The destination route
 * @param from - The origin route
 * @param next - the callback
 */
export const routerBeforeEach = function(this:VueRouter, to:Route, from:Route, next:any) {
    var shopPath = to.params.shopPath;
    var languageCode = this.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];

    var redirectPath = '';
    if(languageCode) { redirectPath += '/'+languageCode; }
    redirectPath += '/404';
    
    var message = "UTILS - VIGNERON-ONLINE - ROUTER BEFORE EACH (To: " + to.fullPath + ") - 404 REDIRECTION ("+redirectPath+")";

    if(to.fullPath == redirectPath) {
        return next();
    }

    var shopPromise = new Promise((resolve, reject) => {
        if(!shopPath) {
            return resolve(null);
        }

        getShopFromPath(this.app, shopPath).then((shop) => {
            if(shop) {
                return resolve(shop);
            }
            else {
                var noShopError = new Error("The shop does not exists");
                noShopError.name = NO_SHOP_ERROR;
                return reject(noShopError);
            }
            })
            .catch((error) => {
                return reject(error)
            })
    });
    
    shopPromise.then((shop) => {
        // We load associated data if needed
        var promises:Promise<void>[] = [];
        
        if(to.meta && to.meta.shop && to.meta.shop.data) {
            for(var data of to.meta.shop.data) {
                if(data == ShopData.SHOPS) {
                    promises.push(updateListShops(this.app));
                }
            }
        }
        return Promise.all(promises).then(() => {
            next();
        })
    })
    .catch((error) => {
        if(process.env.CONSOLE == "LOG") { 
            console.log(message); 
            console.log(error);
        }
        if(error.name && error.name == NO_SHOP_ERROR) {
            // We add some redirect data in store
            this.app.$store.commit('router/' + routerTypes.mutations.ADD_REDIRECT_DATA, {
                "noShopError" : shopPath
            });
        }

        this.app.$store.commit('router/' + routerTypes.mutations.SET_PREVIOUS_ROUTE, to);
        return next(redirectPath);
    })
}

function updateListShops($vm:Vue):Promise<any> {
    return $vm.$store.dispatch('vigneron-online/' + vigneronOnlineTypes.actions.UPDATE_LIST_SHOPS, {
        app:$vm
    });
}

export async function getShopPagePath(pageName:string, $vm:Vue, shop?:any, params?:any) {
    // We get the language Code
    var languageCode = $vm.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

    params = {
        lang : languageCode,
        ...params
    }

    // We check if we are on vigneron-online or not
    if($vm.$vigneronOnline_site) {
        // We are on vigneron-online, we need to add the shop path to the URL
        if(shop) {
            // We check if the shop content loaded is corresponding to the shop in parameter
            if($vm.$shopContent_shop && $vm.$shopContent_shop._id == shop._id) {
                // The current shop content is the one associated to the shop in cart
                params.shopPath = $vm.$shopContent_path;
            }
            else {
                // We need to load the shop content associated to shop before redirection
                await getShopFromID($vm, shop._id);
                params.shopPath = $vm.$shopContent_path;
            }
            
        }
        else {
            // We redirect based on the shop content in store
            params.shopPath = $vm.$shopContent_path
        }
    }

    var path = {
        name : pageName,
        params : params
    };
    return path;
}

export async function redirectToShopPage(pageName:string, $vm:Vue, shop?:any, params?:any) {
    var path = await getShopPagePath(pageName, $vm, shop, params);

    // We redirect to the cart page
    return $vm.$router.push(path).catch(err => {});
}

/**
 * getShopFromPath
 * This method get shop information from path.
 */
function getShopFromPath($vm:Vue, path:string):Promise<any> {
    // We check if the shop in store is the one with path.
    var currentShopContentPath = $vm.$shopContent_path;
    if(currentShopContentPath == path) {
        return Promise.resolve($vm.$shop_shop);
    }

    // We try to get the shop from backend
    var options:api.ApiVueOptions =  {
        app: $vm
    }
    return api.getAPI('/api/vigneron-online/shop/path/'+path, options).then((response:any) => {
        // We update the shop module store with information
        updateShopStoreFromBackend(response, $vm.$store);
        // We also have the shopContent returned for vigneron-online
        updateVigneronOnlineStoreFromBackend(response, $vm.$store);
        return response.shop;
    })
}

/**
 * getShopFromID
 * This method get shop information from id.
 */
 function getShopFromID($vm:Vue, shopID:string):Promise<any> {
    // We check if the shop in store is the one with same id.
    if($vm.$shop_shop && $vm.$shop_shop._id == shopID) {
        return Promise.resolve($vm.$shop_shop);
    }

    // We try to get the shop from backend
    var options:api.ApiVueOptions =  {
        app: $vm
    }
    return api.getAPI('/api/vigneron-online/shop/id/'+shopID, options).then((response:any) => {
        // We update the shop module store with information
        updateShopStoreFromBackend(response, $vm.$store);
        // We also have the shopContent returned for vigneron-online
        updateVigneronOnlineStoreFromBackend(response, $vm.$store);
        return response.shop;
    })
}